import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Link, useNavigate } from 'react-router-dom';
import { TextFieldStyled } from 'components/styled/forms/TextField';
import ErrorText from 'components/styled/forms/ErrorText';
import { ButtonSubmit } from 'components/styled/buttons/ButtonStyled';
import { useAuthContext } from 'auth/ProvideAuth';
import InputTogglePassword from 'components/forms/InputTogglePassword';
import { getFieldStatus } from 'pages/login/utils/loginHelpers';
import { useUserLoginMutation } from 'api/auth/authApi';
import { Loader } from 'components/loader';
import { toast } from 'react-toastify';

const schema = yup.object().shape({
  email: yup.string().email().required(),
  password: yup.string().required('Veuillez saisir votre mot de passe')
});

const LoginForm = () => {
  const auth = useAuthContext();
  const history = useNavigate();

  const [userLogin, { data: userInformation, error, isLoading }] = useUserLoginMutation();

  const {
    register,
    handleSubmit,
    control,
    formState: { errors, touchedFields, dirtyFields }
  } = useForm({
    resolver: yupResolver(schema),
    // mode: 'onBlur',
    defaultValues: {
      email: '',
      password: ''
    }
  });

  const password = register('password');

  const onSubmit = (data) => {
    userLogin({
      email: data.email.replace(/\s/g, ''),
      password: data.password.replace(/\s/g, '')
    });
  };

  React.useEffect(() => {
    if (!error && !isLoading && userInformation) {
      auth.signIn({ data: userInformation?.data, token: userInformation?.token });
      history('/');
    }
    if (error) {
      toast.error('Merci de vérifier vos identifiants');
    }
  }, [userInformation, error, isLoading]);

  if (isLoading) {
    return <Loader width={150} />;
  }

  return (
    <form className='login-form' id='login-form' noValidate autoComplete='off' onSubmit={handleSubmit(onSubmit)}>
      <fieldset form='login-form' disabled={false}>
        <legend>Connexion</legend>
        <p className='login-form__sub-legend'>Saisissez vos identifiants pour accéder à votre espace client A&A</p>
        <Controller
          name='email'
          control={control}
          render={({ field }) => (
            <TextFieldStyled
              type='email'
              {...field}
              placeholder='E-mail'
              hasError={errors.email}
              status={getFieldStatus('email', errors, dirtyFields, touchedFields)}
            />
          )}
          rules={{
            required: 'Veuillez saisir votre e-mail',
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              message: 'invalid e-mail address'
            }
          }}
        />
        {errors.email && <ErrorText>Veuillez saisir votre e-mail</ErrorText>}
        <fieldset className='login-form-field__password'>
          <InputTogglePassword placeholder='Mot de passe*' ref={password.ref} password={password} errors={errors} />
          <Link
            to={{
              pathname: '/password-recovery',
              state: { isDesktop: true }
            }}
          >
            Mot de passe oublié
          </Link>
        </fieldset>

        <ButtonSubmit className='login-form__submit' type='button'>
          Connexion
        </ButtonSubmit>
      </fieldset>
    </form>
  );
};

export default LoginForm;
