import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import RadioButtonComponent from 'components/forms/RadioButtonComponent';
import { ButtonSubmit, IconLabelButtonStyled } from 'components/styled/buttons/ButtonStyled';
import SwitchCheckbox from 'components/forms/SwitchCheckbox';
import Card from 'components/styled/wrappers/Card';
import { useSigneForEventMutation } from 'api/services/meetingEventParticipantApi';
import useModal from 'hooks/useModal';
import ModalProxy from 'pages/meetings/components/ModalProxy';
import { MODAL_TYPE } from 'components/modals/ModalComponent';
import { toast } from 'react-toastify';

const getPresenceDefaultValue = (participant) => {
  if (participant?.hasOwnProperty('present') && participant.present) {
    return '1';
  }

  if (participant?.hasOwnProperty('presentDistance') && participant.presentDistance) {
    return 'presentDistance';
  }

  if (
    (participant?.hasOwnProperty('present') && participant?.present === false) ||
    participant?.hasOwnProperty('proxyParticipant') ||
    participant?.hasOwnProperty('proxyPresident')
  ) {
    return '0';
  }

  return '';
};

const ParticipationForm = ({ meeting }) => {
  const [sendParticipation, { error, isLoading, isSuccess }] = useSigneForEventMutation();
  const { toggle: toggleModal, isOpen, closeModal, isLocked } = useModal(false);

  const PRESENCE = 'presence';
  const PROXY_PARTICIPANT = 'proxyParticipant';
  const PROXY_ENTITY = 'proxyEntity';
  const PROXY_PRESIDENT = 'proxyPresident';

  const initProxyEntity = () => {
    if (meeting?.event?.currentParticipant?.hasOwnProperty(PROXY_PRESIDENT)) {
      return PROXY_PRESIDENT;
    }
    if (meeting?.event?.currentParticipant?.proxyParticipant?.syndic) {
      return 'proxySyndic';
    }
    return null;
  };

  // form hook and utils, from react hook form
  const { register, handleSubmit, watch, reset, getValues, setValue } = useForm({
    defaultValues: {
      proxyParticipant: meeting?.event?.currentParticipant?.proxyParticipant?.id ?? null,
      proxyEntity: initProxyEntity(),
      presence: getPresenceDefaultValue(meeting?.event?.currentParticipant)
    }
  });

  const { ref: presenceRef, ...presenceRegister } = register(PRESENCE, {
    required: 'Veuillez sélectionner une option'
  });
  const { ref: proxyParticipantRef, ...proxyParticipantRegister } = register(PROXY_PARTICIPANT);
  const { ref: proxyEntityRef, ...proxyEntityRegister } = register(PROXY_ENTITY);

  const watchPresence = watch(PRESENCE);
  const watchProxy = watch(PROXY_PARTICIPANT);

  // helpers
  const [isDisabled, setIsDisabled] = useState(!meeting?.event?.currentParticipant?.present);
  const [showProxy, setShowProxy] = useState(
    meeting?.event?.currentParticipant?.hasOwnProperty('proxyParticipant') ||
      meeting?.event?.currentParticipant?.hasOwnProperty(PROXY_PRESIDENT)
  );

  const toggleProxyBlock = () => setShowProxy(!showProxy);
  const showSyndic = () => {
    if (meeting && meeting?.jointOwnership?.trusteeIsProxy) {
      return meeting?.event?.participants?.some((participant) => participant.syndic && participant.present);
    }
  };
  const getSyndicId = () => {
    if (meeting) {
      return meeting?.event?.participants?.find((participant) => participant.syndic)?.id;
    }
  };
  const getSelectedProxy = () => {
    if (meeting) {
      const proxyPerson = meeting?.event?.participants?.find((participant) => {
        return participant.id === parseInt(watch('proxyParticipant')); //&& !participant.syndic;
      });
      if (proxyPerson && !proxyPerson?.syndic && watchProxy !== 'true') {
        return proxyPerson?.owner?.fullName;
      }
    }
  };

  const isSyndicOrProxyPresident = parseInt(watchProxy) === getSyndicId() || watchProxy === 'true';

  const [currentProxyName, setCurrentProxyName] = useState(() => getSelectedProxy());
  useEffect(() => {
    if (isSyndicOrProxyPresident) {
      setCurrentProxyName('');
    } else {
      setCurrentProxyName(getSelectedProxy());
    }
  }, [watchProxy]);

  useEffect(() => {
    if (!showProxy) {
      reset({
        proxyParticipant: null,
        presence: getValues(PRESENCE)
      });
    }
  }, [showProxy]);

  useEffect(() => {
    if (watchPresence === '1' || watchPresence === 'true' || watchPresence === 'presentDistance') {
      setShowProxy(false);
      setIsDisabled(true);
    }
  }, [watchPresence]);

  const onSubmit = (data) => {
    const isSyndic = data.presence === '0' && data.proxyEntity === 'proxySyndic' ? { id: getSyndicId() } : null;
    const isPresident = data.presence === '0' && data.proxyEntity === 'proxyPresident';

    const participationObj = {
      id: meeting?.event?.currentParticipant?.id,
      present: data.presence === '1' || (!isSyndic && !isPresident && !data.proxyParticipant ? false : null),
      presentDistance: data.presence === 'presentDistance' || null,
      proxyPresident: isPresident || null,
      proxyParticipant:
        !isPresident && isSyndic ? isSyndic : data.proxyParticipant ? { id: parseInt(data.proxyParticipant) } : null
    };

    sendParticipation(participationObj);
  };

  const onFormError = (errors) => {
    toast(errors?.presence?.message, {
      type: 'warning'
    });
  };

  useEffect(() => {
    if (error) {
      toast("Votre choix n'a pas pu être enregistré", {
        type: 'error'
      });
    }
    if (isSuccess) {
      toast('Votre choix a bien été enregistré', {
        type: 'success'
      });
    }
  }, [isSuccess, error]);

  return (
    <section className='participation-form-wrapper'>
      <form className='participation-form' id='participation-form' onSubmit={handleSubmit(onSubmit, onFormError)}>
        <fieldset className='participation-form__fieldset'>
          {meeting?.mode !== 2 && (
            <RadioButtonComponent
              labelText={`${meeting?.mode === 0 ? 'Oui' : 'Oui, en présence'}`}
              ref={presenceRef}
              {...presenceRegister}
              value={1}
              isBlock
              isSelected={watchPresence === '1'}
              classes='mb-3'
            />
          )}

          {meeting?.mode > 0 && (
            <RadioButtonComponent
              labelText='Oui, à distance'
              value={'presentDistance'}
              ref={presenceRef}
              {...presenceRegister}
              isBlock
              classes='mb-3'
              isSelected={watchPresence === 'presentDistance'}
            />
          )}

          <RadioButtonComponent
            labelText='Non, je ne participerai pas'
            value={0}
            isBlock
            ref={presenceRef}
            isSelected={watchPresence === '0'}
            {...presenceRegister}
            callback={() => {
              setIsDisabled(false);
              setShowProxy(true);
            }}
          />
        </fieldset>

        <fieldset className='participation-form__fieldset participation-form__participants'>
          <Card>
            <div className='text-start proxy-toggle'>
              <SwitchCheckbox
                onChange={toggleProxyBlock}
                toggled={showProxy}
                text={'Je donne procuration'}
                disabled={isDisabled}
              />
            </div>

            {showProxy && (
              <div className='proxies-management mt-3'>
                {/*Todo replace this by a simple button with icon 'add'*/}
                <IconLabelButtonStyled
                  label={'A un copropriétaire'}
                  handleOnClick={() => toggleModal()}
                  dynamicText={currentProxyName}
                />

                {/*Syndic */}
                {showSyndic() && (
                  <RadioButtonComponent
                    labelText='Au syndic'
                    value={'proxySyndic'}
                    ref={proxyEntityRef}
                    {...proxyEntityRegister}
                    classes={'has-separator'}
                    callback={() => setValue(PROXY_PARTICIPANT, null)}
                  />
                )}

                <RadioButtonComponent
                  labelText='Au Président de la séance'
                  value={'proxyPresident'}
                  ref={proxyEntityRef}
                  {...proxyEntityRegister}
                  classes={'has-separator'}
                  callback={() => setValue(PROXY_PARTICIPANT, null)}
                />
              </div>
            )}
          </Card>
        </fieldset>

        <ButtonSubmit large className='mb-4'>
          Confirmer
        </ButtonSubmit>
      </form>

      {isOpen && (
        <ModalProxy
          modalProps={{ isOpen, closeModal, isLocked }}
          type={MODAL_TYPE.FULL_SCREEN}
          participants={meeting?.event?.participants?.filter(
            (participant) => participant.id !== meeting?.event?.currentParticipant?.id
          )}
          proxyChoiceRef={proxyParticipantRef} // connects to the form
          proxyChoiceRegister={proxyParticipantRegister} // connects to the form
          setValue={setValue}
          watchProxy={watchProxy}
        />
      )}
    </section>
  );
};

export default ParticipationForm;
